@import "../../../../../components/Theme/variables.scss";

.modal-open-educator {
  .list-wrapper {
    background: $white;
    max-height: 21em;
    min-height: 21em;
    overflow: scroll;
    .loader-block{
      height: 100%
    }
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
  .col {
    color: $medium-grey;
  }
  .documents-counter{
    min-width: 60px;
    display: flex;
    justify-content: center;
  }

  .blocked_by_other_device,
  .blocked_by_current_device {
    color: red;

    &.active-item {
      background: red;
      color:white;
    }
  }
}