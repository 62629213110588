@import "../../../node_modules/bootstrap/scss/variables";

$font-size-largest: 32px;
$font-size-large: 24px;
$font-size-normal: 16px;

$primary-color: #ff00ff;
$primary-btn-color: #fff;
$yellow-color: #fffc00;
$selective-yellow-color: #ffb600;
$orange-color: #ff7d0d;
$green-color: #00b211;
$lime-color: #a6ff0d;
$red-color: #ff0c07;
$daisy-bush-color: #4919ac;
$blue-color: #0d94ff;
$blue-ribbon-color: #0051ff;
$seance-color: #9d1db7;
$shiraz-color: #c70c4a;
$medium-grey: #808080;
$light-grey: #b3b3b3;

$background-gray: #f2f2f2;
$background-white: #ffffff;

$font-family-roboto: 'Roboto', sans-serif;
$mark-color:#475876;


$placeholder-color: #909090;
$font-color: #666666;
$font-weight-bold: 600;
$font-weight-normal: 400;
$font-weight-light: 300;
