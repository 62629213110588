.educator-update-page{
  .subtitle{
    width: max-content;
    margin: 20px 0 30px;
  }
}

@media screen and (max-width: 660px) {
  .educator-update-page{
    .subtitle{
      width: auto;
    }
  }
}