.loader-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fixed {
  top:0;
  left: 0;
  position: fixed;
  min-height: 100vh;
  min-width: 100vh;
  z-index: 100;
}

.absolute {
  top:0;
  left: 0;
  position: absolute;
  z-index: 100;
}

.dark-fade {
  background: rgba(0, 0, 0, 0.8);
}

.light-fade {
  background: rgba(0, 0, 0, 0.2);
}

.backdrop-blur {
  backdrop-filter: blur(2px);
}