@font-face {
  font-family: 'sac-font';
  src: url('font/sac.eot?58535109');
  src: url('font/sac.eot?58535109#iefix') format('embedded-opentype'),
       url('font/sac.woff2?58535109') format('woff2'),
       url('font/sac.woff?58535109') format('woff'),
       url('font/sac.ttf?58535109') format('truetype'),
       url('font/sac.svg?58535109#sac-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'sac-font';
    src: url('../font/sac-font.svg?58535109#sac-font') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "sac-font";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-cardcvv:before { content: '\e800'; } /* '' */
.icon-cardnumber:before { content: '\e801'; } /* '' */
.icon-clipboard:before { content: '\e802'; } /* '' */
.icon-duplicate:before { content: '\e803'; } /* '' */
.icon-magicwand-plus:before { content: '\e804'; } /* '' */
.icon-magicwand-minus:before { content: '\e805'; } /* '' */
.icon-magicwand:before { content: '\e806'; } /* '' */
.icon-circle_tool:before { content: '\e807'; } /* '' */
.icon-rectangle_tool:before { content: '\e808'; } /* '' */
.icon-shape_tool:before { content: '\e809'; } /* '' */
.icon-mobile_help:before { content: '\e80a'; } /* '' */
.icon-mobile_login:before { content: '\e80b'; } /* '' */
.icon-mobile_curve_tool_pointer:before { content: '\e80c'; } /* '' */
.icon-mobile_pointer_move+delete:before { content: '\e80d'; } /* '' */
.icon-mobile_pointer_move:before { content: '\e80e'; } /* '' */
.icon-mobile_move:before { content: '\e80f'; } /* '' */
.icon-mobile_pointer:before { content: '\e810'; } /* '' */
.icon-mobile_logout:before { content: '\e811'; } /* '' */
.icon-mobile_settings:before { content: '\e812'; } /* '' */
.icon-mobile_contact:before { content: '\e813'; } /* '' */
.icon-mobile_tour:before { content: '\e814'; } /* '' */
.icon-mobile_export:before { content: '\e815'; } /* '' */
.icon-mobile_save|open:before { content: '\e816'; } /* '' */
.icon-mobile_show_maps:before { content: '\e817'; } /* '' */
.icon-mobile_import_image:before { content: '\e818'; } /* '' */
.icon-mobile_clear:before { content: '\e819'; } /* '' */
.icon-mobile_drawing_tool_curves:before { content: '\e81a'; } /* '' */
.icon-keypad:before { content: '\e81b'; } /* '' */
.icon-mac_osx:before { content: '\e81c'; } /* '' */
.icon-cvv_code:before { content: '\e81d'; } /* '' */
.icon-zoom_out:before { content: '\e81e'; } /* '' */
.icon-zoom_in:before { content: '\e81f'; } /* '' */
.icon-windows:before { content: '\e820'; } /* '' */
.icon-up_arrow:before { content: '\e821'; } /* '' */
.icon-undo_tool:before { content: '\e822'; } /* '' */
.icon-twitter:before { content: '\e823'; } /* '' */
.icon-toolbar_minimize:before { content: '\e824'; } /* '' */
.icon-toolbar_handle:before { content: '\e825'; } /* '' */
.icon-sum_all_layers:before { content: '\e826'; } /* '' */
.icon-submit_long_lat:before { content: '\e827'; } /* '' */
.icon-select_tool:before { content: '\e828'; } /* '' */
.icon-search_map:before { content: '\e829'; } /* '' */
.icon-scale_tool:before { content: '\e82a'; width: 35px; margin: 0; } /* '' */
.icon-results_perim:before { content: '\e82b'; } /* '' */
.icon-results_area:before { content: '\e82c'; } /* '' */
.icon-redo_tool:before { content: '\e82d'; } /* '' */
.icon-password:before { content: '\e82e'; } /* '' */
.icon-pan_tool:before { content: '\e82f'; } /* '' */
.icon-nav_down:before { content: '\e830'; } /* '' */
.icon-month:before { content: '\e831'; } /* '' */
.icon-map_icn:before { content: '\e832'; } /* '' */
.icon-long_lat_icn:before { content: '\e833'; } /* '' */
.icon-layer_right:before { content: '\e834'; } /* '' */
.icon-layer_left:before { content: '\e835'; } /* '' */
.icon-grid_icn:before { content: '\e836'; } /* '' */
.icon-firstnamelastname:before { content: '\e837'; } /* '' */
.icon-facebook:before { content: '\e838'; } /* '' */
.icon-extra_tool:before { content: '\e839'; } /* '' */
.icon-enteremail:before { content: '\e83a'; } /* '' */
.icon-email:before { content: '\e83b'; } /* '' */
.icon-drawing_tool:before { content: '\e83c'; } /* '' */
.icon-drawing_tool_curves:before { content: '\e83d'; } /* '' */
.icon-drawing_tool_curves_active:before { content: '\e83e'; } /* '' */
.icon-drawing_tool_constrained:before { content: '\e83f'; } /* '' */
.icon-drawing_tool_constrained_active:before { content: '\e840'; } /* '' */
.icon-drawing_tool_active:before { content: '\e841'; } /* '' */
.icon-download:before { content: '\e842'; } /* '' */
.icon-down_arrow:before { content: '\e843'; } /* '' */
.icon-comment:before { content: '\e844'; } /* '' */
.icon-comment_tool:before { content: '\e845'; } /* '' */
.icon-close_icn:before { content: '\e846'; } /* '' */
.icon-blog:before { content: '\e847'; } /* '' */
.icon-apple:before { content: '\e848'; } /* '' */
.icon-annotate_text:before { content: '\e849'; } /* '' */
.icon-annotate_rotate:before { content: '\e84a'; } /* '' */
.icon-annotate_move:before { content: '\e84b'; } /* '' */
.icon-annotate_delete:before { content: '\e84c'; } /* '' */
.icon-angle_tool:before { content: '\e84d'; } /* '' */
.icon-angle_tool_b:before { content: '\e84e'; } /* '' */
.icon-angle_tool_a:before { content: '\e84f'; } /* '' */
.icon-android:before { content: '\e850'; } /* '' */
.icon-add_new_layer:before { content: '\e851'; } /* '' */
.icon-action_menu:before { content: '\e852'; } /* '' */
.icon-checkmark:before { content: '\e853'; } /* '' */

.icon-svg {
    fill: #fff;
    width: 0.7em;
    margin-right: .2em;
    text-align: center;
    vertical-align: middle;
}