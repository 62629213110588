.switch-input {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 20px;
  margin: 2px 10px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #485876;
    border-radius: 15px;
    -webkit-transition: .4s;
    transition: .4s;

    &:before{
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0;
      bottom: 0;
      background-color: white;
      border: 1px solid #b3b3b3;
      border-radius: 50%;
      -webkit-transition: .4s;
      transition: .4s;
      transform: translateX(26px);
    }
  }
}

.switch-input input:checked + .slider:before {
  transform: translateX(0);
}
.switch-input input:disabled + .slider::before {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(179, 179, 179, 0.3);
}