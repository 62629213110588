.dropdown {
  position: relative;

  select.form-control {
    height: 54px;
    border: 1px solid #e8e8e8;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
  }

  .select-arrow {
    pointer-events: none;
    z-index: 1;

    &:before, &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:before {
      top: 25px;
      right: 20px;
      border-width: 10px 7.5px 0 7.5px;
      border-color: #7f7f7f transparent transparent transparent;
    }

    &:after {
      top: 24px;
      right: 21px;
      border-width: 9px 6.5px 0 6.5px;
      border-color: #fff transparent transparent transparent;
    }
  }
}