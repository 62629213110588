@import "../../../../components/Theme/variables.scss";

.modal-save-open-student {
  .list-wrapper {
    background: $white;
    max-height: 21em;
    min-height: 21em;
    overflow: auto;
    .loader-block{
      height: 100%
    }
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
  .col {
    &.save {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      button {
        max-height: 40px;
        min-height: 40px;
        padding: 0 30px;
      }
    }

    &.lists{
      margin-top: 25px;
      padding: 0 15px;
    }
  }
  .session-name{
    width: 100%;
    height: 42px;
  }

  .lists-row {
    justify-content: center;
    display: flex;
    align-items: flex-end;
    button {
      max-height: 40px;
      margin-right: 15px;
      padding: 0 30px;
      margin-left: 0;
    }

    .blocked_by_other_device,
    .blocked_by_current_device {
      color: red;

      &.active-item {
        background: red;
        color:white;
      }
    }
  }
  .error-col {
    min-height:20px;
    .details-block {
      font-size: 13px;
      padding: 0 10px;

      &.details_error {
        color: red;
      }
    }
  }
  .message-row{
    min-height: 30px;
    .messages{
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
      }
    }
  }
  .session-item{
    .session-item-name {
      word-break: break-all;
    }
    .session-item-time {
      font-size: 12px;
      margin-top: 5px;
      text-align: right;
    }
  }
}