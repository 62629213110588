@import "../../../components/Theme/variables.scss";

.modal-close{
  z-index: 100;
  font-size: 30px;
  padding: 10px;
  color: $medium-grey;
  background: $light-grey;
  position: absolute;
  right: 0;
  cursor: pointer;
  opacity: 0.8;
  top:0;

  i {
    &::before{
      font-weight: 800;
    }
  }

  &:hover {
    opacity: 1;
  }
}