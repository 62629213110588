@import "../Theme/variables.scss";

.suffix-block {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #Layer_1 {
    fill: $light-grey;

    &:hover,
    &:active {
      fill: $medium-grey;
    }
  }
}

.active-suffix {
  #Layer_1 {
    fill: $medium-grey;
  }
}
