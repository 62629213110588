@import "../../components/Theme/variables.scss";

.educator-register {
  padding: 0 15px;

  .location-block {
    display: flex;
    justify-content: center;
    align-items: baseline;
    max-width: 500px;
    width: 100%;

    .prefix {
      margin-right: 10px;
      color: $medium-grey;
    }

    div:nth-child(n+2) {
      width: 100%;
    }

    .form-input {
      width: 100%;
    }
  }

  .badge-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img{
      margin-top: 40px;
      max-width: 100px;
      margin-bottom: 10px;
    }
    .subtitle {
      margin-bottom: 10px;
    }
  }


  select{
    font-weight: lighter;
  }

  select:invalid{
    color:$placeholder-color;
  }
}