.modal-save-open {
  .container-fluid{
    max-width: 100%;
    margin: 15px 0 10px;
    transition: margin .2s linear;
    &.message {
      margin: 15px 0 20px;
    }
    .col.save{
      padding: 0 5px 0 10px;
      input{
        max-width: 100%;
      }
    }
    .col.open {
      padding: 0;
    }
  }
  .content {
    padding: 0;
    display: flex;
    .btn-modal {
      padding: 0 5px;
      text-align: center;
      &:disabled {
        cursor: not-allowed;
      }
      &.light {
        background: transparent;
        border: 1px solid #bbb;
        color: #bbb;

        &:hover:enabled {
          border: 1px solid #d9534f;
          color: #d9534f;
        }
      }
      .btn-success {
        background: inherit;
      }
      &.success-danger {
        background: #d9534f;
        border: 1px solid #d9534f;
        color: #fff;
        &:hover:enabled {
          border: 1px solid #d9534f;
          color: #fff;
        }
      }
      &.active-danger:enabled {
        border: 1px solid #d9534f;
        color: #d9534f;
        &:hover:enabled {
          border: 1px solid #d9534f;
          background: #d9534f;
          color: #fff;
        }
      }
    }



    .left-block {
      width: calc(100% - 125px);
      margin-right: 20px;
      .form-control {
        height: 40px;
      }
    }
    .right-block {
      width: 105px;
      display: grid;
      grid-template-rows: 40px 40px 1fr 40px;
      grid-gap: 15px;
      button {
        width: 105px;
      }
    }
    .table-block {
      margin-top: 15px;
    }
    .col.open {
      &:first-child{
        flex-basis: 60%;
      }
      &:last-child{
        flex-basis: 40%;
      }
      .wrapper-list {
        hr {
          margin: 3px 10px;
        }
        .sort-actions {
          background: none;
          padding: 5px 10px 2px;
          .icon {
            padding: 0 5px !important;
          }
        }
        .list {
          .item {
            padding: 0 10px;
            svg {
              margin-left: 5px;
              path {
                fill: #666;
              }
            }
            &.active {
              background: #808080;
              svg {
                margin-left: 5px;
                path {
                  fill: #fff;
                }
              }
            }
            &.archived {
              color: lightgrey;
              &.active {
                background: #d9534f;
                color: #fff;
                svg {
                  margin-left: 5px;
                  path {
                    fill: #fff;
                  }
                }
              }
              svg {
                margin-left: 5px;
                path {
                  fill: lightgrey;
                }
              }
              &:hover {
                background: #d9534f;
                color: #fff;
                svg {
                  margin-left: 5px;
                  path {
                    fill: #fff;
                  }
                }
              }
            }
            &.blocked_by_other_device,
            &.blocked_by_current_device {
              color: #d9534f;

              &.active {
                background: #d9534f;
                color:white;
              }
            }

            .name {
              max-width: 58%;
              white-space: normal;
              word-break: break-word;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .details-block{
      position: absolute;
      margin-top: 10px;
      font-size: 13px;
      padding: 0 10px;
      bottom: -20px;
      &.details_error{
        color: #d9534f;
      }
    }
  }
}