@import "../../../../Theme/variables.scss";

.session-tab {
  .creds-block {
    display: flex;
    justify-content: space-between;

    .cred {
      flex-basis: 49%;

      .label {
        padding: 0 20px;
        color: $light-grey;
        font-weight: 300;
      }

      .form-input {
        border: 1px solid #e8e8e8;
      }

      .form-input::placeholder {
        color: $light-grey;
        font-weight: 300;
      }

      .form-control {
        cursor: pointer;
      }

      .password {
        text-align: center;
      }

      .password:disabled {
        background: $light-grey;
      }

      .password::placeholder {
        color: white;
        letter-spacing: 3px;
        font-size: 20px;
      }

      .disabled-session-name{
        background: white;
      }

      .disabled-session-password:disabled {
        background-color: $green-color;
        color: white;
        font-size: 25px;
      }
    }
  }
  .primary-btn {
    width: 100%;
    font-weight: 200;
    margin-top: 20px;
  }
  .counter{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 90px;
    color: $light-grey;
    padding: 40px 0;

    &.active-counter{
      color: $green-color;
    }
  }
  .secondary-btn,
  .outlined-btn,
  .error-btn{
    margin: 10px 0;
  }
}