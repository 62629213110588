.input-measure {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    color: #000;
    width: 90px;
    overflow: hidden;
    height: 34px;
    padding: 0 2px;
    margin: 0 4px;
    box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.9);


    input {
        padding: 0 !important;
        outline: none;
    }
}
