@import "../../Theme/variables";
.layer-label-container {
  width: 110px;
  position: absolute;
  left: 10px;
  top: 10px;
  background:rgba(0,0,0,.8);
  z-index: 6;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.3);

  &.move-down {
    top: 65px;
  }

  &.minimize {
    height: 25px;
    padding: 0;
    border-radius: 0;
    .layer-label-actions {
      margin: 0;
    }
  }

  ul.layer-label-actions {
    display: flex;
    height: 25px;

    flex-flow: row nowrap;
    list-style: none;
    background-color: #465877;
    justify-content: space-between;
    padding: 0 3px;
    margin: 0;

    li {
      width: 25px;
      cursor: default!important;
      &:before{
        cursor: pointer!important;
      }


    }

    .icon {
      padding: 2px 0;
      font-size: 14px;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    .turn {
      padding: 7px 8px 7px 6px;
      &.off:before {
        content: ' ';
        display: block;
        height: 10px;
        border-style: solid;
        border-color: #fff;
        border-width: 4px 1px 1px;
      }
      &.on:before {
        content: ' ';
        display: block;
        height: 10px;
        border-style: solid;
        border-color: #fff;
        border-width: 3px 0 0;
      }
    }
  }

  .action-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    &.disabled {
      svg {
        path {
          fill: #666666;
        }
      }
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .layers-legend{
    overflow: hidden;
    max-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .layer-label {
      display: flex;
      justify-content: flex-start;
      font-size: 12px;

      .text {
        color: #fff;
        padding: 2px 10px 2px 5px;
      }

      .layer-id {
        padding: 2px 5px 2px 10px;
        &.pink { color: $primary-color; }
        &.yellow { color: $yellow-color; }
        &.selective-yellow { color: $selective-yellow-color; }
        &.orange { color: $orange-color; }
        &.green { color: $green-color; }
        &.lime { color: $lime-color; }
        &.red { color: $red-color; }
        &.daisy-bush { color: $daisy-bush-color; }
        &.blue { color: $blue-color; }
        &.blue-ribbon { color: $blue-ribbon-color; }
        &.seance { color: $seance-color; }
        &.shiraz { color: $shiraz-color; }
      }
    }

    .switch-container {
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      padding: 10px 5px 10px 10px;
    }
  }
}