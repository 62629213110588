@import "../../Theme/variables.scss";

.list-wrapper{
  position: relative;
  min-height: 155px;
  &.no-scroll{
    overflow: hidden!important;
  }

  .loader-block {
    margin-top: 24px;
    padding: 15px 0;
  }

  .list-title{
    background: #e6e6e6;
  }

  .list-item{
    min-height: 35px;
    display: flex;
    align-items: center;
    word-break: break-all;
    &.active-item{
      background: $font-color;
      color: white;

      &:hover{
        background: $font-color;
      }
    }
    &:hover{
      cursor: pointer;
      background-color: #eaeaea;
    }
  }
}