@import "../../../components/Theme/variables.scss";

.thank-you-wraper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .thank-you-block {
    padding: 0 15px;

    .title{
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: $font-color;
      margin-bottom: 10px;
    }

    .subtitle{
      text-align: center;
      color: $font-color;
      margin-bottom: 20px;
      font-weight: 300;
    }
  }
}



