.register-from_app {
	text-align: center;

	p {
		color: #808080;
	}

	a.btn.transparent {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 440px;
		background: none;
		border: 1px solid #ff00ff;
		color: #ff00ff;
		line-height: 36px;
		font-family: sans-serif;
		font-weight: bold;

		&:hover {
			background: #ff00ff;
			color: #fff;
		}
	}
}
